import { Component } from "react";
import VideosService from "services/VideosService";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert2'
import open from "../images/open.png"
import { withRouter } from "react-router-dom";
import { APIURL } from "services/Host";

class ListaVideos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id_asignatura: sessionStorage.getItem("id_asignatura"),
            nombre_asignatura: sessionStorage.getItem("nombre_asignatura"),
            id_tema: sessionStorage.getItem("id_tema"),
            nombre_tema: sessionStorage.getItem("nombre_tema"),
            id_subtema: sessionStorage.getItem("id_subtema"),
            nombre_subtema: sessionStorage.getItem("nombre_subtema"),
            id_video: '',
            nombre_video: '',
            descripcion: '',
            videos: [],
            modalArchivo: false,
            url: ""
        }
    }

    ModalVideo(id_video) {
        this.state.url = VideosService.url() + "/get_video/" + id_video;
        this.setState({ modalArchivo: !this.state.modalArchivo });
    }

    componentDidMount() {
        VideosService.VideosActivosbySubtema(this.state.id_subtema).then((res) => {

            if (res.data.length > 0) {
                this.setState({ videos: res.data, videosTemp: res.data });
            }
            else {
                swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No existen videos ',
                });
                this.setState({ videos: [], videosTemp: [] });
            }
        })
    }

    goBackAsignaturas = () => {
        this.props.history.push("/app/AcademicoAlumnosAsignaturas");
    }

    goBackTemas = () => {
        this.props.history.push("/app/ListaTemasAlumnos");
    }

    goBackSubtemas = () => {
        this.props.history.push("/app/ListaSubtemasAlumnos");
    }

    render() {
        return (
            <div className='bg-primary_2 py-10'>
                <div className="flex justify-start mx-10 mt-4">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1">
                            <li class="flex justify-end">
                                <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </a>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackAsignaturas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Asignaturas</a>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackTemas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de temas</a>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackSubtemas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de subtemas</a>
                                </div>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Lista de videos</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest'>
                    {this.state.nombre_subtema}
                </h1>
                <div class=' px-2 md:px-10 pt-4 md:pt-3  overflow-y-auto'>
                    <div class='grid grid-cols-3 gap-10'>
                        {
                            this.state.videos.map(
                                (video, index) =>
                                    <div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
                                        {video.extension_Imagen != "" ?
                                            <div class='flex justify-center'>
                                                <img
                                                    src={APIURL + '/video/get_imagen/' + video.ID_Video}
                                                    class='w-10 h-10 sm:w-16 sm:h-16'
                                                    alt=''></img>

                                            </div>
                                            :
                                            <div class='flex w-10 h-10 sm:w-16 sm:h-16'>
                                            </div>
                                        }
                                        <div class='flex justify-center py-2'>
                                            <h1 class='text-xl text-secondary font-black counter'>
                                                {video.Nombre_Video}
                                            </h1>
                                        </div>
                                        <div class='flex justify-center'>
                                            <h1 class='text-white text-xl font-barlow tracking-widest'>{video.Descripcion}</h1>
                                        </div>
                                        <div class='flex justify-center pt-3'>
                                            <button class="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 " onClick={() => this.ModalVideo(video.ID_Video)} title="Ver video">
                                                <svg class="h-7 w-7 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                                            </button>
                                        </div>

                                    </div>

                            )
                        }
                    </div>
                </div>
                {this.state.modalArchivo ? (
                    <>
                        <div div class="overflow-y-auto h-32">
                            <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                    {/*content*/}
                                    <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                        {/*header*/}
                                        <div className='rounded-t-3xl'>
                                            <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Ver video</p>
                                        </div>
                                        {/*body*/}

                                        <div className='relative flex-auto'>
                                            <div class='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                                <div className="shadow-sm2 px-5 py-2 rounded-3xl">
                                                    <div>
                                                        <video
                                                            id="vistaVideo"
                                                            title="Video"
                                                            width="100%"
                                                            height="400"
                                                            controls
                                                            //controlsList="nodownload"
                                                            oncontextmenu="return false;"
                                                            buffered
                                                        >
                                                            <source src={this.state.url} type="video/mp4"></source>
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*footer*/}
                                        <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                            <button
                                                className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                type='button'
                                                onClick={() => this.setState({ modalArchivo: false })}>
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div onClick={() => this.setState({ modalArchivo: false })} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                            </div>

                        </div>
                    </>
                ) : null}
            </div>
        )
    }
}


export default withRouter(ListaVideos);